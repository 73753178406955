import React from 'react';
import api from '../../lib/Api';
import CommandPanel from './panels/command/CommandPanel';
import WaitPanel from './panels/WaitPanel';
import ConfirmPanel from './panels/ConfirmPanel';
import Segment from '../../lib/models/Segment';
import { RemoteCommand } from '../../lib/models/RemoteCommand';
import Installation from '../../lib/models/Installation';
import Select from 'react-select';
import './SendCommands.css';
import { navigate, RouteComponentProps } from '@reach/router';
import { theme } from '../../lib/causelinkSelectTheme';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';

interface Props extends RouteComponentProps {
    id?: string;
}

interface State {
    segment: Segment;
    commands: RemoteCommand[];
    installations: Installation[];
    targets: Installation[];
    component: string | null | undefined,

    confirm: boolean;
    wait: boolean;
}

export default class SendCommands extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            installations: [],
            targets: [],
            component: null,
            commands: [],
            segment: new Segment(),

            confirm: false,
            wait: false
        };

        api.segments.find(this.props.id!).then((segment) => {
            this.setState({ segment });

            api.segments.listInstallations(segment).then((installations) => this.setState({ installations }));
        });
    }

    public render() {
        if ((this.state.segment === undefined)) {
            return <WaitPanel />;
        }

        if (this.state.confirm) {
            return <ConfirmPanel action="send commands" onCancel={() => this.setState({ confirm: false })} onConfirm={this.onAdd.bind(this)} />;
        }

        let name = (this.state.component == null) ? 'any component' : `${this.state.component} component`;
        name += ' of ' + ((this.state.targets.length > 0)
            ? this.state.targets.map((c) => c.name).join(', ')
            : 'all instances on this segment');

        const phony = new Installation({ name });

        const installations = this.state.installations.map((installation) => { return { value: installation.id, label: installation.name }; });
        const layers = [{ value: 'api', label: 'API' }, { value: 'service', label: 'Service' }];

        return <div id="SendCommands" className='section container'>
            <h3 className='subtitle'>Installations &gt; {this.state.segment?.name} &gt; Send Commands</h3>

            <div className="field instances">
                <label className='label'>Instances</label>
                <Select
                    theme={theme}
                    options={installations}
                    isMulti={true}
                    classNamePrefix='react-select'
                    onChange={(selected) => this.setTargets(selected.map((o) => o.value))} />
            </div>

            <div className="field component">
                <label className='label'>Component</label>
                <Select
                    className='control'
                    theme={theme}
                    options={layers}
                    classNamePrefix='react-select'
                    onChange={(selected) => this.setState({ component: selected?.value })} />
            </div>

            <CommandPanel installation={phony} commands={this.state.commands} onCommandUpdate={(commands) => this.setState({ commands })} />

            <div className='buttons is-right'>
                <button className='button' onClick={() => navigate(-1)}>Cancel</button>
                <button className='button is-primary' onClick={() => this.setState({ confirm: true })}> Send</button>
            </div>
        </div>;
    }

    private onAdd = () => {
        this.setState({ wait: true });

        const resolve = () => navigate(`/installations/segment/${this.state.segment.id}`);

        const targets = (this.state.targets.length > 0) ? this.state.targets : this.state.installations;
        const promises: (() => Promise<string | void>)[] = [];

        targets.forEach((target) => {
            const messageGroupId = v4().toString();

            this.state.commands.forEach((command) => {
                command.messageGroupId = messageGroupId;
                promises.push(api.commands.process.bind(this, { ...command, installationId: target.id, component: this.state.component }));
            });
        });

        promises.push(resolve);

        let chain: Promise<any> = Promise.resolve();
        promises.forEach(command => {
            chain = chain.then(command);
        });

        Promise.all(promises).then(
            () => navigate(`/installations/segment/${this.state.segment.id}`).then(() => toast('Sent commands successfully!', { type: 'success' })),
            () => toast('Something went wrong...', { type: 'error' })
        );
    }

    private setTargets(ids: string[]) {
        const targets = this.state.installations.filter((installation) => ids.includes(installation.id));

        this.setState({ targets });
    }
}