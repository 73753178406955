export default class Installation {
    public id: string = '';
    public segmentId: string | undefined;
    public name: string | undefined;
    public dns: string | undefined;
    public connectionString: string | undefined;
    public cfDistributionId: string | undefined;
    public version: string | undefined;
    public versionMatch: string | undefined;

    public constructor(partial?: Partial<Installation>) {
        Object.assign(this, partial);
    }
}